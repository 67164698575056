import React, { useState, useRef, useEffect } from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;
const MAX_HEIGHT = 60; // Adjust this value to set the maximum height before truncation

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  useEffect(() => {
    if (textRef.current) {
      const contentHeight = textRef.current.scrollHeight;
      setShowButton(contentHeight > MAX_HEIGHT);
      if (contentHeight <= MAX_HEIGHT) {
        setExpanded(true); // Ensure content is fully visible if it fits
      }
    }
  }, [text]);

  const toggleExpanded = () => setExpanded(!expanded);

  return text ? (
    <div className={css.sectionText}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </Heading>
      {heading && (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      )}
      <div
        ref={textRef}
        className={`${textClass} ${expanded ? css.expanded : css.truncated}`}
        style={{ maxHeight: expanded ? `${textRef.current?.scrollHeight}px` : `${MAX_HEIGHT}px` }}
      >
        <p>{content}</p>
      </div>
      <div className={css.buttonWrapper}>
        {showButton && (
          <button onClick={toggleExpanded} className={css.toggleButton}>
            {expanded ? 'Show less' : 'Learn more about this item'}
          </button>
        )}
      </div>
    </div>
  ) : null;
};

export default SectionTextMaybe;
